/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useEffect, useReducer, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useInterval, useScroll, useWindowDimensions } from "~hooks";
import { getRandomIntByRange } from "~utils/helpers";

import { ReactComponent as Arrow } from "~assets/icons/arrow-light.svg";

import defaultImage from "~assets/images/atf-headline.svg";

const CURSOR_RADIUS = 90;
const FOG_STEP = 40;
const MAX_FOG = 3000;
const MAX_SMUDGE_BLUR = 14;
const MAX_CONDENSATION_THICKNESS = 20;

const ClipWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  position: relative;
  overflow: hidden;
  display: block;
  clip: rect(auto, auto, auto, auto);
  clip-path: inset(0 0 0 0);
`;

const FixedUnderlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClippedContainer = styled.div`
  width: 100%;
  position: absolute;
  transform: translate3d(0, 0, 0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const GlassContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const FrostedGlass = styled.div`
  @keyframes appear {
    0% {
      opacity: 0;
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  animation: 5s ease-in-out appear forwards;
  opacity: 0;
  // background: rgba(255, 255, 255, 0.025);

  &:before {
    content: "";
    position: absolute;
    background: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 75vw rgba(255, 255, 255, 0.7);
    filter: blur(14px);
  }
`;

const ContentContainer = styled.div`
  @keyframes scale-in {
    0% {
      opacity: 0;
      transform: scale(0.975);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  animation: 3s ease-in-out scale-in forwards;

  width: 65%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// =============================================================================
// interval reducer

const reducer = (state, action) => {
  switch (action.type) {
    case `defog`:
      return 0;
    case `fog`:
      return state + FOG_STEP;
    default:
      return state;
  }
};

// =============================================================================
// inner wrapping component

const SectionWrapper = ({ children, foreground, background }) => (
  <ClipWrapper
    css={css`
      background: ${background};
      color: ${foreground};
    `}
  >
    <GlassContainer>
      <FrostedGlass />
    </GlassContainer>

    {children}
  </ClipWrapper>
);

// =============================================================================
// core runnable parent

const LandingATF = ({ image = defaultImage }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop, windowSize } = useWindowDimensions();
  const { scrollTop } = useScroll();

  const containerRef = useRef();
  const cursorRef = useRef();
  const intervalRef = useRef();
  const maskingRef = useRef();
  const svgRef = useRef();

  const [fog, dispatch] = useReducer(reducer, 0);

  //
  // user smudging
  const [smudgeBlur, setSmudgeBlur] = useState(2);
  const [smudgeThickness, setSmudgeThickness] = useState(CURSOR_RADIUS);
  //
  // condensation
  const [condensationBlur, setCondensationBlur] = useState(0);
  const [condensationPaths, setCondensationPaths] = useState([]);
  const [condensationHidden, setCondensationHidden] = useState(false);
  const [condensationThickness, setCondensationThickness] = useState(
    MAX_CONDENSATION_THICKNESS
  );
  //
  // user fogging time
  const [fogging, setFogging] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  const primaryColor = `#7437D2`;
  const secondaryColor = `#F6BDEE`;

  // ---------------------------------------------------------------------------
  // methods

  const resetCondensationPaths = () => {
    if (!windowSize?.width) {
      return;
    }

    const origin = {
      x: getRandomIntByRange(0, windowSize.width),
      y: 0
    };

    const condensationGroups = [origin];

    let currentIndex = 0;

    while (
      condensationGroups?.[condensationGroups.length - 1]?.y < windowSize.height
    ) {
      const positive = Math.random() >= 0.5;
      const lastPoint = condensationGroups[currentIndex];
      const y = lastPoint.y + getRandomIntByRange(1, 25);
      const xDiff = getRandomIntByRange(1, 4);

      let { x } = lastPoint;

      if (positive) {
        x += xDiff;
      } else {
        x -= xDiff;
      }

      condensationGroups.push({
        x,
        y
      });

      currentIndex += 1;
    }

    setCondensationPaths([condensationGroups]);
  };

  const resetPolyline = (id) => {
    const polyline = document.querySelector(`#${id}`);

    if (polyline?.points) {
      polyline.points.clear();
    }

    return polyline;
  };

  const resetSmudge = () => {
    if (typeof intervalRef?.current === `number`) {
      clearInterval(intervalRef.current);

      intervalRef.current = null;
    }

    dispatch({ type: `defog` });

    resetPolyline(`poly-smudge`);

    setSmudgeBlur(0);
    setSmudgeThickness(0);
    setSmudgeThickness(CURSOR_RADIUS);
  };

  const drawCondensation = () => {
    setCondensationHidden(false);
    setCondensationThickness(20);
    setCondensationBlur(2);

    const polyline = resetPolyline(`poly-condensation`);

    condensationPaths.forEach((pathGroup) => {
      pathGroup.forEach(({ x, y }, pointIndex) => {
        setTimeout(() => {
          const point = svgRef.current.createSVGPoint();

          point.x = x;
          point.y = y;

          polyline.points.appendItem(point);
        }, pointIndex * 42);
      });
    });
  };

  const drawLine = (e) => {
    const polyline = document.querySelector(`#poly-smudge`);
    const marginOffset = 0;
    const objectOffset = 0;
    const point = svgRef.current.createSVGPoint();

    point.x = e.clientX - (marginOffset + objectOffset);
    point.y = e.clientY - (marginOffset + objectOffset);

    polyline.points.appendItem(point);

    const cx = e.clientX - (marginOffset + objectOffset);
    const cy = e.clientY - (marginOffset + objectOffset);

    cursorRef.current.setAttribute(`transform`, `translate(${cx},${cy})`);
  };

  // ---------------------------------------------------------------------------
  // lifecycle - init

  useEffect(() => {
    if (!containerRef?.current || !svgRef?.current) {
      return () => {};
    }

    const container = containerRef.current;

    const onMouseEnter = (e) => {
      resetPolyline(`poly-smudge`);
      resetSmudge();

      container.addEventListener(`mousemove`, drawLine);

      setFogging(false);
    };

    const onMouseLeave = (e) => {
      container.removeEventListener(`mousemove`, drawLine);

      setFogging(true);
    };

    container.addEventListener(`mouseenter`, onMouseEnter, false);
    container.addEventListener(`mouseleave`, onMouseLeave, false);

    return () => {
      container.removeEventListener(`mouseenter`, onMouseEnter, false);
      container.removeEventListener(`mouseleave`, onMouseLeave, false);
    };
  }, [containerRef, svgRef, fog]);

  useEffect(() => {
    if (typeof intervalRef?.current === `number`) {
      clearInterval(intervalRef.current);

      intervalRef.current = null;

      return () => {};
    }

    if (!fogging) {
      return () => {};
    }

    const interval = setInterval(() => {
      requestAnimationFrame(() => {
        dispatch({ type: `fog` });
      });
    }, FOG_STEP);

    intervalRef.current = interval;

    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [fogging]);

  useEffect(() => {
    if (fog === 0) {
      setSmudgeThickness(CURSOR_RADIUS);
    } else if (fog > MAX_FOG) {
      resetSmudge();
    } else {
      const progressPercent = 1 - fog / MAX_FOG;
      const newBlur = MAX_SMUDGE_BLUR * (1 - progressPercent);
      const newThickness = CURSOR_RADIUS * progressPercent;

      setSmudgeBlur(newBlur);
      setSmudgeThickness(newThickness);
    }
  }, [fog]);

  // ---------------------------------------------------------------------------
  // lifecycle - condensation

  //
  // 1. set a fixed interval that randomly redraws condensation
  useInterval(() => {
    if (!windowSize?.width) {
      return () => {};
    }

    setCondensationHidden(true);

    const resetTimeout = setTimeout(() => {
      resetCondensationPaths();
    }, 3000);

    return () => {
      clearTimeout(resetTimeout);
    };
  }, 10000);

  //
  // 2. [condensationHidden] blur existing condensation paths before a redraw
  useEffect(() => {
    if (!condensationHidden || !condensationPaths?.[0]) {
      return () => {};
    }

    const interval = setInterval(
      requestAnimationFrame(() => {
        let newBlur = condensationBlur;
        let newThickness = condensationThickness;

        newBlur += 0.25;
        newThickness -= 0.25;

        if (newThickness < 0) {
          clearInterval(interval);
        } else {
          setCondensationBlur(newBlur);
          setCondensationThickness(newThickness);
        }
      }),
      82
    );

    return () => {
      clearInterval(interval);
    };
  }, [condensationBlur, condensationHidden, condensationThickness]);

  //
  // 3. [condensationPaths] draw the newly defined paths (called in resetCondensationPaths())
  useEffect(() => {
    if (!condensationPaths?.[0]) {
      return;
    }

    drawCondensation();
  }, [condensationPaths]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      css={css`
        position: relative;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 20;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 3rem 0;
          text-align: center;
          text-transform: uppercase;
          color: var(--color-white);
        `}
      >
        <h3
          css={css`
            animation: var(--animation-appear-down);
            animation-duration: 3s;
          `}
          className="button"
        >
          It’s 29°C in PERTH
        </h3>

        <div
          css={css`
            animation: var(--animation-appear-up);
            animation-duration: 3s;
            animation-delay: 5s;

            transform: translate3d(0, 1rem, 0);
            opacity: 0;
          `}
        >
          <div
            css={css`
              animation: float var(--animation-float);
            `}
          >
            <Arrow
              css={css`
                width: 1rem;
              `}
              fill="white"
            />
          </div>
        </div>
      </div>

      {/* // */}

      <SectionWrapper background={primaryColor} foreground={secondaryColor}>
        <div
          ref={containerRef}
          css={css`
            width: 100vw;
            height: 100vh;
            position: absolute;
            top: -2rem;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 50;
          `}
        >
          <svg
            ref={svgRef}
            css={css`
              position: relative;
              display: block;
            `}
            width={windowSize?.width || 0}
            height={windowSize?.height || 0}
            viewBox={`0 0 ${windowSize?.width || 0} ${windowSize?.height || 0}`}
          >
            <defs>
              <filter
                id="ultraBlur"
                width="150%"
                height="150%"
                x="-25%"
                y="-25%"
                colorInterpolationFilters="sRGB"
              >
                <feGaussianBlur stdDeviation="45" />
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0 0 0 0 10 0"
                />
                <feGaussianBlur stdDeviation="45" />
                <feColorMatrix
                  type="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0 0 0 0 10 0"
                />
                <feComposite in2="SourceGraphic" operator="in" />
              </filter>

              <mask
                ref={maskingRef}
                id="masking"
                x="0"
                y="0"
                width={windowSize?.width || 0}
                height={windowSize?.height || 0}
              >
                {/* style={{ filter: `blur(${condensationBlur}px)` }} */}
                <polyline
                  id="poly-condensation"
                  points=""
                  filter="url(#ultraBlur)"
                  fill="transparent"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={condensationThickness}
                />

                {/* style={{ filter: `blur(${smudgeBlur}px)` }} */}
                <polyline
                  id="poly-smudge"
                  points=""
                  filter="url(#ultraBlur)"
                  fill="transparent"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={smudgeThickness}
                />
              </mask>
            </defs>

            <g mask="url(#masking)">
              <rect
                x="0"
                y="0"
                width={windowSize?.width || 0}
                height={windowSize?.height || 0}
                fill={primaryColor}
              />
              <image
                xlinkHref={image}
                x="0"
                y="0"
                width={windowSize?.width || 0}
                height={windowSize?.height || 0}
              />
            </g>

            <circle
              ref={cursorRef}
              cx="0"
              cy="0"
              r={CURSOR_RADIUS / 2}
              fillOpacity="0.4"
              fill="#ffffff"
            />
          </svg>
        </div>

        <ContentContainer
          css={css`
            @keyframes unblur {
              0% {
                opacity: 0;
                filter: blur(24px);
              }
              100% {
                opacity: 1;
                filter: blur(0);
              }
            }

            animation: 10s ease unblur forwards;
          `}
        >
          <img
            css={css`
              filter: blur(24px);

              width: ${windowSize?.width || 0}px;
              height: ${windowSize?.height || 0}px;
              position: relative;
              display: block;
            `}
            src={image}
            alt="Delicious low sugar ice cream is no longer a frozen fantasy."
          />
        </ContentContainer>
      </SectionWrapper>
    </section>
  );
};

export default LandingATF;
